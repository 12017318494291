import { _isMobile, _on } from '../../../utils';
const submenuButtons = 'header__submenu';
const subMenuClass = 'header__menu-desktop-dropdown';
const skrimClass = 'header__menu-nav-skrim';
const skrimActiveClass = 'active-skrim';
const dataDropdown = 'data-dropdown';
const dataSubmenu = 'data-submenu';
const openingClsDropdown = 'hs-dropdown__open';
const closingClsDropdown = 'hs-dropdown__close';
const openingClsMenu = 'hs-menu__open';
const closingClsMenu = 'hs-menu__close';

function debounce(quietMillis, fn, ctx) {
  ctx = ctx || undefined;
  var timeout;
  return function () {
    var args = arguments;
    window.clearTimeout(timeout);
    timeout = window.setTimeout(function () {
      fn.apply(ctx, args);
    }, quietMillis);
  };
}

function cleanAllClasses() {
  try {
    // If isMobile
    if (window && _isMobile()) {
      const subMenus = document.querySelectorAll(`.${subMenuClass}`);
      const menuButtons = document.querySelectorAll(`.${submenuButtons}`);
      const skrim = document.querySelector(`.${skrimClass}`);
      // Clear skrim from active
      skrim.classList.remove(skrimActiveClass);
      // Close all
      subMenus.forEach(subMenu => {
        subMenu.classList.remove(openingClsDropdown, closingClsDropdown);
      });
      // Close all
      menuButtons.forEach(button => {
        button.classList.remove(openingClsMenu, closingClsMenu);
      });
    }
  } catch (e) {
    console.warn(`Class cleanup ${e.message}`);
  }
}
// Clean closing/opening classes on resize with debounce
const cleanClassesDebounced = debounce(500, cleanAllClasses, window);
window.addEventListener('resize', cleanClassesDebounced);

// Mymic click outside (pointer events none when off)
_on('click', skrimActiveClass, e => {
  const subMenus = document.querySelectorAll(`.${subMenuClass}`);
  const menuButtons = document.querySelectorAll(`.${submenuButtons}`);
  // Close all
  subMenus.forEach(subMenu => {
    if (subMenu.classList.contains(openingClsDropdown)) {
      subMenu.classList.replace(openingClsDropdown, closingClsDropdown);
    }
  });
  // Close all
  menuButtons.forEach(button => {
    if (button.classList.contains(openingClsMenu)) {
      button.classList.replace(openingClsMenu, closingClsMenu);
    }
  });
  e.target.classList.toggle(skrimActiveClass, false);
});
// MenuSwitch with dropdown
_on('click', submenuButtons, e => {
  e.preventDefault();
  const clickedElement = e.target;
  const targetID = clickedElement.getAttribute(dataSubmenu);

  if (targetID) {
    try {
      const subMenus = document.querySelectorAll(`.${subMenuClass}`);
      const bgShade = document.querySelector(`.${skrimClass}`);
      // Getting current state will it generally CLOSE dropdown or OPEN it
      const { isSomeSubmenuOpen, isTargetSameAsSubmenuOpen } = Array.from(subMenus).reduce(
        (acc, subMenu) => {
          const subMenuID = subMenu.getAttribute(dataDropdown);
          const isOpen = subMenu.classList.contains(openingClsDropdown);
          if (isOpen) {
            Object.assign(acc, {
              isSomeSubmenuOpen: true,
            });
            if (subMenuID === targetID) {
              Object.assign(acc, {
                isTargetSameAsSubmenuOpen: true,
              });
            }
          }
          return acc;
        },
        {
          isSomeSubmenuOpen: false,
          isTargetSameAsSubmenuOpen: false,
        },
      );
      if (isSomeSubmenuOpen && isTargetSameAsSubmenuOpen) {
        bgShade.classList.toggle(skrimActiveClass, false);
      } else {
        bgShade.classList.toggle(skrimActiveClass, true);
      }
      subMenus.forEach(subMenu => {
        const subMenuID = subMenu.getAttribute(dataDropdown);
        const isOpen = subMenu.classList.contains(openingClsDropdown);
        // Target menu open close
        if (targetID === subMenuID) {
          // If current target menu is open - close it
          if (isOpen) {
            subMenu.classList.toggle(openingClsDropdown, false);
            subMenu.classList.toggle(closingClsDropdown, true);
            clickedElement.classList.toggle(openingClsMenu, false);
            clickedElement.classList.toggle(closingClsMenu, true);
            // If current target menu is closed - open it
          } else {
            subMenu.classList.toggle(openingClsDropdown, true);
            subMenu.classList.toggle(closingClsDropdown, false);
            clickedElement.classList.toggle(openingClsMenu, true);
            clickedElement.classList.toggle(closingClsMenu, false);
          }
        } else {
          // If NOT current target menu is open - close it
          if (isOpen) {
            subMenu.classList.toggle(openingClsDropdown, false);
            subMenu.classList.toggle(closingClsDropdown, true);
            const correspondingButton = document.querySelector(`[${dataSubmenu}=${subMenuID}]`);
            correspondingButton.classList.replace(openingClsMenu, closingClsMenu);
          }
        }
      });
    } catch (e) {
      console.warn(e);
    }
  }
});
