// TODO: update splide version
import Splide from '@splidejs/splide';
import queryString from 'query-string';
import tippy from 'tippy.js';

import './js/common/header';
import '../utils/helpers/web-vitals';

import {
  _initGTM,
  _initSplide,
  _isMobile,
  _isOnScreen,
  _on,
  _once,
  _scrollTo,
  AUTH_EVENT_NAME,
  configureShadowArea,
  initSplideWidget,
} from '../utils';

import initTabs from './js/tabs';

import '@splidejs/splide/dist/css/splide.min.css';
import './index.scss';
import './css/design-system.css';
import './css/header.css';
import './css/redesign.css';
import './css/footer.css';
import './css/landing.css';
import './css/landing-v2.css';
import './css/table.css';
import './css/our-team.css';
import 'tippy.js/dist/tippy.css';
import './css/hiw-block.css';

window.dataLayer = window.dataLayer || [];
const MOBILE_FLOW_BREAKPOINT = 431;

const backToTop = document.querySelector('.back-to-top');

_isOnScreen(
  '.header__beacon',
  () => {
    document.querySelector('header').classList.remove('fixed');
    document
      .querySelectorAll('.header__menu-desktop-dropdown')
      .forEach(dropdown => dropdown.classList.remove('real-fixed'));
    document.querySelector('body').classList.remove('with-fixed-header');
  },
  {},
  () => {
    document.querySelector('header').classList.add('fixed');

    document
      .querySelectorAll('.header__menu-desktop-dropdown')
      .forEach(dropdown => dropdown.classList.add('real-fixed'));
    document.querySelector('body').classList.add('with-fixed-header');
  },
);

_isOnScreen(
  '.nav__beacon',
  () => {
    document.querySelector('.place-order').classList.remove('scrolled');
    document.querySelector('.place-order-mobile').classList.remove('scrolled');
    // Jira 4262
    // document.querySelector('.section--page-nav').classList.remove('scrolled');
    // document.querySelector('.section--subheader-cta').classList.remove('scrolled');
    // document.querySelector('header').classList.remove('with-page-nav');
  },
  { threshold: 0.2 },
  () => {
    document.querySelector('.place-order').classList.add('scrolled');
    document.querySelector('.place-order-mobile').classList.add('scrolled');
    // document.querySelector('.section--page-nav').classList.add('scrolled');
    // document.querySelector('.section--subheader-cta').classList.add('scrolled');
    // document.querySelector('header').classList.add('with-page-nav');
  },
);

_isOnScreen('.section--order', () => {}, {});

_isOnScreen(
  '.header__beacon',
  () => backToTop.classList.add('hidden'),
  {},
  () => backToTop.classList.remove('hidden'),
);

_isOnScreen('.hiw__beacon', () => document.querySelector('.hiw__list').classList.add('animate'));

_on('click', 'js--back-to-top', () => {
  _scrollTo('.header__beacon', { threshold: 72 + 60 });
});

// @todo add helpcrunch
_on('click', 'js--trigger-livechat', () => {

});

_on('click', 'get-started', e => {
  _scrollTo('.header__beacon', {
    cb: () => {
      if (document.getElementById('id_email')) {
        document.getElementById('id_email').focus();
      }
    },
  });
});

_on('click', 'js--order', e => {
  window.bbSetOrder({ threshold: 400 });
});

_on('click', 'js--new-order', e => {
  window.bbSetOrder({});
});

_on('click', 'js--order-paper-type', e => {
  const paper_type = parseInt(e.target.dataset.type, 10) || 200;
  window.bbSetOrder({ paper_type });
});

_on('click', 'js--request-writer', ({ target }) => {
  window.bbSetOrder({ domain: '/', writer_req_slug: target.dataset.slug, threshold: 240 });
});

_on('click', 'js--flow-order', () => {
  if (window.innerWidth > MOBILE_FLOW_BREAKPOINT) {
    window.bbSetOrder({ threshold: 350 });
  } else {
    window.location.href = '/registration.html';
  }
});

['.tabs--reviews', '.tabs--prices', '.es-reviews-tabs', '.latest-orders__tabs'].forEach(tab =>
  initTabs(tab),
);

_on('click', 'js--banner-new', () => {
  document.querySelector('.banner--new').classList.add('hidden');
  localStorage.setItem('banner--new', true);
});

_on('click', 'js--cookie-policy', () => {
  document.querySelector('.policies').classList.add('hidden');
  localStorage.setItem('policy|usage', true);
});

function closeAllDrawerSubmenus() {
  const subMenus = document.querySelectorAll('.drawer-submenu');
  subMenus.forEach(subMenu => {
    subMenu.classList.remove('active-drawer-submenu');
  });
}

_on('click', 'js--drawer-close', () => {
  document.body.classList.remove('drawer--active');
  closeAllDrawerSubmenus();
});

_on('click', 'js--drawer', () => {
  document.body.classList.add('drawer--active');
});

_on('click', 'drawer__overlay', () => {
  document.body.classList.remove('drawer--active');
  closeAllDrawerSubmenus();
});

_on('click', 'js--drawer-submenu', e => {
  const subMenuGroup = e.target.getAttribute('data-submenu');
  const menuToOpen = document.querySelector(`.drawer-submenu-${subMenuGroup}`);
  if (menuToOpen) {
    menuToOpen.classList.add('active-drawer-submenu');
  }
});

_on('click', 'drawer-submenu-close-button', closeAllDrawerSubmenus);

if (document.querySelector('.banner--new')) {
  if (localStorage.getItem('banner--new')) {
    document.querySelector('.banner--new').classList.add('hidden');
  } else {
    document.querySelector('.banner--new').classList.remove('hidden');
  }
}

if (document.querySelector('.policies')) {
  if (localStorage.getItem('policy|usage')) {
    document.querySelector('.policies').classList.add('hidden');
  } else {
    document.querySelector('.policies').classList.remove('hidden');
  }
}
_on('click', 'js--click-anchor-child', e =>
  e.target.closest('.js--click-anchor-child').querySelector('a').click(),
);

_isOnScreen(
  '.splide--hiw',
  _once(() => {
    const splide = new Splide('.splide--hiw', {
      perPage: 1,
      arrows: true,
      pagination: true,
      fixedHeight: 340,
      breakpoints: {
        1024: {
          fixedHeight: false,
        },
      },
    });

    splide.on('pagination:mounted', function (data) {
      data.list.classList.add('splide__pagination--hiw');
      data.items.forEach(function (item) {
        item.button.textContent = `Step ${item.page + 1}`;
      });
    });
    splide.mount();
  }),
);
_isOnScreen(
  '.splide--dtb-block',
  _once(() => {
    const splide = new Splide('.splide--dtb-block', {
      type: 'fade',
      width: 713,
      pauseOnFocus: false,
      pauseOnHover: false,
      perPage: 1,
      pagination: false,
      fixedHeight: 566,
      resetProgress: true,
      breakpoints: {
        320: {
          width: 300,
          arrows: true,
          fixedHeight: false,
        },
        375: {
          width: 360,
          arrows: true,
          fixedHeight: false,
        },
        425: {
          width: 410,
          arrows: true,
          fixedHeight: false,
        },
        558: {
          width: 545,
          arrows: true,
          fixedHeight: false,
        },
        1024: {
          arrows: true,
          fixedHeight: false,
          width: 713,
        },
        4096: {
          arrows: false,
          width: 713,
        },
      },
    });
    const discipline_tabs = document.querySelectorAll('.dtb__step');

    // side desktop step buttons click
    discipline_tabs.forEach(function (button) {
      button.addEventListener('click', function () {
        var slideIndex = button.getAttribute('data-slide');
        splide.go(slideIndex);
      });
    });

    splide.on('active', function ({ index }) {
      discipline_tabs.forEach((step, stepIndex) => {
        step.classList.toggle('active', index === stepIndex);
      });
    });

    splide.mount();
  }),
);
_isOnScreen(
  '.splide--hiw-block',
  _once(() => {
    const splide = new Splide('.splide--hiw-block', {
      type: 'fade',
      rewind: true,
      autoplay: true,
      interval: 5000,
      width: 558,
      pauseOnFocus: false,
      pauseOnHover: false,
      perPage: 1,
      pagination: false,
      fixedHeight: 493,
      resetProgress: true,
      breakpoints: {
        320: {
          width: 300,
          arrows: true,
          fixedHeight: false,
        },
        375: {
          width: 360,
          arrows: true,
          fixedHeight: false,
        },
        425: {
          width: 410,
          arrows: true,
          fixedHeight: false,
        },
        558: {
          width: 545,
          arrows: true,
          fixedHeight: false,
        },
        1024: {
          arrows: true,
          fixedHeight: false,
          width: 558,
        },
        4096: {
          arrows: false,
          width: 558,
        },
      },
    });
    const desktopSteps = document.querySelectorAll('.hiw__step');
    // side desktop step buttons click
    desktopSteps.forEach(function (button) {
      button.addEventListener('click', function () {
        var slideIndex = button.getAttribute('data-slide');
        splide.go(slideIndex);
      });
    });

    splide.on('active', function ({ index }) {
      desktopSteps.forEach((step, stepIndex) => {
        step.classList.toggle('active', index === stepIndex);
      });
    });

    splide.mount();

    if (_isMobile()) {
      const maxStepHeight = [...desktopSteps].reduce((max, el) => {
        return Math.max(max, el.clientHeight);
      }, 0);

      const stepsContainer = document.querySelector('.hiw__steps');
      stepsContainer.style.minHeight = `${maxStepHeight}px`;
    } else {
      const stepDescriptions = document.querySelectorAll('.hiw__step-description');
      const maxDescriptionHeight = [...stepDescriptions].reduce((max, el) => {
        return Math.max(max, el.clientHeight);
      }, 0);

      stepDescriptions.forEach(el => {
        el.style.height = `${maxDescriptionHeight}px`;
      });
    }
  }),
);

initSplideWidget('splide--reviews', {
  breakpoints: {
    600: {
      perPage: 1,
      fixedWidth: 312,
    },
    1024: {
      perPage: 2,
    },
    4096: {
      perPage: 3,
    },
  },
});
initSplideWidget('splide--sitejabber', {
  breakpoints: {
    600: {
      perPage: 1,
      fixedWidth: 312,
    },
    1024: {
      perPage: 2,
    },
    4096: {
      perPage: 3,
    },
  },
});
initSplideWidget('splide--reviewsIO', {
  breakpoints: {
    600: {
      perPage: 1,
      fixedWidth: 312,
    },
    1024: {
      perPage: 2,
    },
    4096: {
      perPage: 3,
    },
  },
});
initSplideWidget('splide--best-writers');
initSplideWidget('splide--writing-app');
initSplideWidget('splide--essay-app', { arrows: false });
initSplideWidget('splide--jobs', {
  arrows: true,
  pagination: false,
  perPage: 2,
  breakpoints: {
    1023: {
      perPage: 1,
      arrows: false,
      pagination: true,
    },
  },
});
initSplideWidget('splide--social-media', {
  arrows: false,
  breakpoints: {
    600: {
      perPage: 1,
      pagination: true,
      fixedWidth: 264,
    },
    1024: {
      perPage: 3,
      pagination: true,
    },
    4096: {
      pagination: false,
      perPage: 4,
    },
  },
});
initSplideWidget('splide--mention', {
  autoWidth: true,
  breakpoints: {
    600: {
      perPage: 1,
      arrows: false,
      pagination: true,
    },
    1024: {
      perPage: 2,
      arrows: false,
      pagination: true,
    },
    4096: {
      pagination: false,
      perPage: 5,
    },
  },
});

_on('click', 'js--accordion-rotation', e => {
  const button = e.target.closest('.accordion__item-button');
  const container = button ? button.closest('.accordion__item') : undefined;

  if (button) {
    if (!container.classList.contains('accordion__item--active')) {
      const activeIndex = container.getAttribute('data-index');
      const imageContainers = document.querySelector('.js--ar-rotation');
      if (imageContainers && activeIndex) {
        const classes = ['left', 'top', 'right', 'bottom'];
        const classRegex = new RegExp(/^fy-.+-sm$/, 'g');
        const caseMap = {
          1: [1, 2, 3, 4],
          2: [2, 3, 4, 1],
          3: [3, 4, 1, 2],
          4: [4, 1, 2, 3],
        };
        Array.from(imageContainers.children).forEach((imageContainer, index) => {
          if (!imageContainer.classList.contains('fy-with-transition'))
            imageContainer.classList.add('fy-with-transition');
          const previousClass = [...imageContainer.classList].find(className =>
            className.match(classRegex),
          );
          const newClass = `fy-${classes[caseMap[activeIndex][index] - 1]}-sm`;
          if (previousClass !== newClass) {
            imageContainer.classList.add(`fy-${classes[caseMap[activeIndex][index] - 1]}-sm`);
            setTimeout(() => imageContainer.classList.remove(previousClass), 0);
          }
        });
      }
      if (container.parentElement.querySelector('.accordion__item--active')) {
        container.parentElement
          .querySelector('.accordion__item--active')
          .classList.remove('accordion__item--active');
      }
      setTimeout(() => {
        container.classList.add('accordion__item--active');
      }, 400);
    }
  }
});

_on('click', 'js--accordion', e => {
  const button = e.target.closest('.accordion__item-button');
  const container = button ? button.closest('.accordion__item') : undefined;

  if (button) {
    if (container.classList.contains('accordion__item--active')) {
      container.classList.remove('accordion__item--active');
    } else {
      if (container.parentElement.querySelector('.accordion__item--active')) {
        container.parentElement
          .querySelector('.accordion__item--active')
          .classList.remove('accordion__item--active');
      }
      container.classList.add('accordion__item--active');
    }
  }
});

_on('click', 'js--seo-space', e => {
  if (document.querySelector('.seo-space_hidden')) {
    document
      .querySelectorAll('.seo-space_hidden')
      .forEach(item => item.classList.add('seo-space_visible'));
    e.target.remove();
  }
});

_on('click', 'js--dropdown', ({ target }) => {
  if (
    document.querySelector('.dropdown--active') &&
    document.querySelector('.dropdown--active') !== target.closest('.dropdown')
  ) {
    document.querySelector('.dropdown--active').classList.remove('dropdown--active');
  }
  target.closest('.dropdown').classList.toggle('dropdown--active');
});

if (document.getElementById('auth_path')) {
  const parsed = queryString.parse(location.search);

  if (parsed.url) {
    document.getElementById('auth_path').innerText = new URL(parsed.url, location.origin).pathname;
  }
}

if (_isMobile()) {
  ['.splide--why', '.splide--subjects', '.splide--books'].forEach(s => _initSplide(s));
} else {
  ['.splide--books'].forEach(s => _initSplide(s));
}

_initGTM();

window.addEventListener('resize', e => {
  if (_isMobile()) {
  } else {
  }
});

if (document.getElementById('log_in')) {
  document.getElementById('log_in').addEventListener('click', function (e) {
    if (document.querySelector('.bb-accountEmptyButton')) {
      document.querySelector('.bb-accountEmptyButton').click();
    } else {
      window.location.href = '/dashboard/customer/orders?tab=recent';
    }
  });
}

_isOnScreen(
  '#reviewBadgeWidget',
  _once(() => {
    (function (d, s, id, c) {
      var js,
        r = 0,
        t = d.getElementsByTagName(s)[0];
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://static.sitejabber.com/js/widget.min.1630101102.js';
      js.onload = js.onreadystatechange = function () {
        if (!r && (!this.readyState || this.readyState[0] == 'c')) {
          r = 1;
          c();
        }
      };
      t.parentNode.insertBefore(js, t);
    })(document, 'script', 'sj-widget', function () {
      new STJR.ReviewBadgeWidget({
        id: 'reviewBadgeWidget',
        url: 'essayshark.com',
        type: 'large',
      }).render();
    });
  }),
);

_isOnScreen(
  '#reviewCarouselWidgetInner',
  _once(() => {
    (function (d, s, id, c) {
      var js,
        r = 0,
        t = d.getElementsByTagName(s)[0];
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://static.sitejabber.com/js/widget.min.1621646744.js';
      js.onload = js.onreadystatechange = function () {
        if (!r && (!this.readyState || this.readyState[0] == 'c')) {
          r = 1;
          c();
        }
      };
      t.parentNode.insertBefore(js, t);
    })(document, 'script', 'sj-widget', function () {
      new STJR.ReviewCarouselWidget({
        id: 'reviewCarouselWidget',
        url: 'essayshark.com',
      }).render();
    });
  }),
);

_isOnScreen(
  '#reviewPageWidgetInner',
  _once(() => {
    (function (d, s, id, c) {
      var js,
        r = 0,
        t = d.getElementsByTagName(s)[0];
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://static.sitejabber.com/js/widget.min.1621646744.js';
      js.onload = js.onreadystatechange = function () {
        if (!r && (!this.readyState || this.readyState[0] == 'c')) {
          r = 1;
          c();
        }
      };
      t.parentNode.insertBefore(js, t);
    })(document, 'script', 'sj-widget', function () {
      new STJR.ReviewPageWidget({ id: 'reviewPageWidget', url: 'essayshark.com' }).render();
    });
  }),
);

_on('click', 'js--to-reviews', () => {
  _scrollTo('.section--reviews', { threshold: 20 });
});

_on('click', 'ourwriters-read-more', e => {
  document.querySelector('.our-writers__seo-text').classList.remove('seo-text--hidden');
  e.target.remove();
});

const navSelectors = [
  { nav: 'js--scroll-hiw', target: 'js--scroll-hiw-target', threshold: 140 },
  { nav: 'js--scroll-writers', target: 'js--scroll-writers-target', threshold: 140 },
  { nav: 'js--scroll-reviews', target: 'js--scroll-reviews-target', threshold: 0 },
  { nav: 'js--scroll-features', target: 'js--scroll-features-target', threshold: 90 },
  { nav: 'js--scroll-pricing', target: 'js--scroll-pricing-target', threshold: 140 },
  { nav: 'js--scroll-whyus', target: 'js--scroll-whyus-target', threshold: 90 },
];
const itemSelector = 'page-nav__item';
const itemSelectorActive = 'page-nav__item_active';

navSelectors.forEach(({ nav, target, threshold }, index) => {
  const navItem = document.querySelector(`.${nav}`);
  _on('click', nav, e => {
    if (e.target.closest(`.${itemSelector}`).classList.contains(itemSelectorActive)) {
      return;
    }
    _scrollTo(`.${target}`, {
      cb: () => {
        const currentActiveElement = document.querySelector(`.${itemSelectorActive}`);
        if (currentActiveElement) {
          currentActiveElement.classList.remove(itemSelectorActive);
        }
        e.target.closest(`.${itemSelector}`).classList.add(itemSelectorActive);
      },
      threshold,
    });
  });

  _isOnScreen(
    `.${target}`,
    () => {
      const activeItems = document.querySelectorAll(`.${itemSelectorActive}`);
      activeItems.forEach(item => item.classList.remove(itemSelectorActive));
      if (navItem) {
        navItem.classList.add(itemSelectorActive);
      } else {
        return;
      }

      const scrollPadding = index * 24;
      if (_isMobile()) {
        let scrollArea = document.querySelector('.section--page-nav .page-nav');
        if (navItem.classList.contains('js--scroll-hiw')) {
          scrollArea.scroll(0, 0);
        } else {
          scrollArea.scroll(navItem.getBoundingClientRect().x / 2 + scrollPadding, 0);
        }
      }
    },
    { threshold: _isMobile() ? 0 : 0.75 },
    () => {
      if (navItem) {
        navItem.classList.remove(itemSelectorActive);
      }
    },
  );
});

if (_isMobile()) {
  _isOnScreen('.section--page-nav', () => {
    let scrollArea = document.querySelector('.section--page-nav .page-nav');
    configureShadowArea(scrollArea);
    _once(() => {
      scrollArea.addEventListener('scroll', function () {
        configureShadowArea(scrollArea);
      });
    })();
  });
}

if (document.querySelector('.section--new-stats')) {
  _isOnScreen(
    '.about-video__container',
    _once(() => {
      document
        .getElementById('youtube-frame')
        .contentWindow.document.querySelector('.youtube-video')
        .click();
    }),
  );
}

if (document.querySelector('.registration')) {
  window.bbSetOrder({});
}

_on('click', 'copy-to-clipboard', function (e) {
  navigator.clipboard.writeText(
    e.target.closest('.copy-to-clipboard').getAttribute('data-content'),
  );
});

/**
 * Custom lazy loading for images in slider
 * To target image add placeholder url in src attribute, original img url set in data-src attr.
 * @see SHARK-2492
 */

const observerConfig = {
  rootMargin: '0px 0px 5% 0px',
  threshold: 0,
};

let lazyLoadObserver = new IntersectionObserver(function (entries, self) {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      preloadImage(entry.target);
      self.unobserve(entry.target);
    }
  });
}, observerConfig);

function preloadImage(img) {
  img.src = img.dataset.src;
}

const lazyImages = document.querySelectorAll('[data-src]');
lazyImages.forEach(img => {
  lazyLoadObserver.observe(img);
});

/**
 * TODO: refactor old tabs
 * if page consist two or more tabs instances only one stay workable
 */

if (document.querySelector('.tabs__container')) {
  document.addEventListener('click', ({ target }) => {
    if (target.classList.contains('tab__button')) {
      const activeTab = 'tab__button--active';
      const activeContent = 'tab__content--active';

      if (target.classList.contains(activeTab)) {
        return;
      }
      // tabs
      document.querySelector(`.${activeTab}`).classList.remove(activeTab);
      target.classList.add(activeTab);

      // content
      document.querySelector(`.${activeContent}`).classList.remove(activeContent);
      document
        .querySelector(`#${target.id.replace('tab__button', 'tab__content')}`)
        .classList.add(activeContent);
    }
  });
}

/**
 * TODO refactor
 * this code related to /sample-papers.html
 */

_on('click', 'review_expander', ({ target }) => {
  const wrapper = target.closest('.review_wrapper');

  if (wrapper.classList.contains('visible')) {
    wrapper.classList.remove('visible');
    return (target.textContent = 'Show more');
  }
  wrapper.classList.add('visible');
  return (target.textContent = 'Show less');
});

document.addEventListener(AUTH_EVENT_NAME, e => {
  const userRole = e.detail && e.detail.user_role;
  const orderMobileFlow = document.getElementById('order-mobile-flow');
  const authFlowLink = document.querySelectorAll('.js--auth-flow-link');

  // Logout
  if (!userRole) {
    if (orderMobileFlow) {
      orderMobileFlow.classList.remove('hidden');
    }

    authFlowLink.forEach(e => {
      e.setAttribute('href', '/registration.html');
    });
  }
});

/**
 * Our-samples search
 */
const searchForm = document.querySelector('.our-samples__search-container');
const searchInput = document.getElementById('searchInput');

if (searchForm && searchInput) {
  searchForm.addEventListener('submit', function (event) {
    event.preventDefault();

    const inputValue = searchInput.value.trim();

    if (inputValue) {
      const url = `http://examples.essayshark.com/?s=${encodeURIComponent(inputValue)}`;
      window.open(url, '_blank');
      searchInput.value = '';
    }
  });
}

tippy('.tippy-safe-payment', {
  content:
    '<b>SafePayments</b> <br> Risk-free method of paying for your order. Reserve funds, release part-by-part only when you like the result. All unreleased funds are 100% refundable.',
  allowHTML: true,
});

tippy('[data-tippy-content]');

// Bookstore smooth scroll
_on('click', 'js--scroll-to-books', () => {
  _scrollTo('#next-section--books', { threshold: 20 });
});

_on('click', 'js--scroll-to-author', () => {
  _scrollTo('#next-section--author', { threshold: 20 });
  const body = document.body;
  if (body.classList.contains('drawer--active')) {
    body.classList.remove('drawer--active');
    closeAllDrawerSubmenus();
  }
});
