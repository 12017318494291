import './tabs.css';

/**
 *
 * @param {Object|string} config Tabs configuration object or container selector;
 * @param {string} config.containerSelector Tabs parent component selector
 * @param {string} config.tabToggleSelector Tabs parent component selector
 * @param {string} config.tabContentSelector Tabs parent component selector
 * @param {function|undefined} config.openCallback Tabs parent component selector
 * @param {function|undefined} config.initCallback Tabs parent component selector
 */
function initTabs(config) {

  const options = {
    containerSelector : '.tabs',
    tabToggleSelector : '.tabs__toggle',
    tabContentSelector : '.tabs__content',
    activeClass : 'tab_active',
    openCallback: undefined,
    initCallback: undefined,
    ...( typeof config === 'string' ? { containerSelector: config } : config ),
  };

  const {
    containerSelector,
    tabToggleSelector,
    tabContentSelector,
    activeClass,
    openCallback,
    initCallback,
  } = options;

  const container = document.querySelector(containerSelector);

  if(!container) {
    return;
  }

  const toggles = container.querySelectorAll(tabToggleSelector);
  const contentTabs = container.querySelectorAll(tabContentSelector);
  let tabName;

  toggles.forEach((toggle,index) => {
    if(!index) {
      toggles[0].classList.add(activeClass);
      contentTabs[0].classList.add(activeClass);
    }
    toggle.addEventListener('click', selectTab);
  });

  function selectTab(e) {
    toggles.forEach(toggle => {
      toggle.classList.remove(activeClass);
    });

    this.classList.add(activeClass);
    tabName = this.dataset.tab;
    selectTabContent(tabName);

    if(openCallback) {
      openCallback(e);
    }
  }

  function selectTabContent(tabName) {
    contentTabs.forEach(contentTab => {
      contentTab.classList.contains(tabName) ? contentTab.classList.add(activeClass) : contentTab.classList.remove(activeClass);
    });
  }

  function init() {
    if (initCallback) {
      initCallback();
    }

    container.style.visibility = 'visible';
  }

  init();
}

export default initTabs;
